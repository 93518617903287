:root {
  --red: #f35218;
  --light-grey: #f0f0f0;
  --light-grey-color: #919191;
  --middle-gray: #3e3e3e;
  --dark-grey: #25292c;
  --nav-grey: #5f6469;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: var(--light-grey);
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  cursor: pointer;
}

.mat-header-row {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  background-color: inherit;
}

mat-cell {
  overflow-x: auto !important;
}

.ti-mat-dialog-actions---end {
  justify-content: flex-end !important;
}

.ti-mat-dialog-actions--space-between {
  justify-content: space-between !important;
}

.ti-mat-dialog-header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ti-mat-dialog-header-with-button > mat-dialog-actions {
  margin: 0;
  width: auto;
}

.ti-toggle-sign > span.mat-option-text {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
}

.ti-toggle-sign > span.mat-option-text > mat-icon.ti-toggle-sign--first {
  margin-bottom: -18px !important;
}

snack-bar-container.mat-snack-bar-container {
  min-width: fit-content;
  word-break: break-all;
  background: #5f6469;
}

button.mat-simple-snackbar-action {
  color: #20c997;
}

.mat-simple-snackbar {
  min-width: fit-content;
  word-break: break-all;
}

.ti-family__value-wrapper .mat-form-field-infix {
  width: 133px;
}

.ti-family__data .mat-form-field-infix {
  width: 139px;
}

.ti-language-settings__thin-inputs .mat-form-field-infix {
  width: 140px;
}

.ti-common-cues__table .mat-form-field-infix {
  width: 122px;
}

.right-aligned-header > .mat-content {
    justify-content: space-between;
}

.mat-content > mat-panel-title, .mat-content > mat-panel-description {
  flex: 0 0 auto;
}


.tooltiptext {
  background-color: #707070;
  border-radius: 3px;
  color: #ebebeb;
  font-size: 80%;
  padding: 5px;
  z-index: 10000;
  max-width: 600px;
}

.mat-tooltip {
  white-space: pre-line !important;
}

#lexemeEditDialog {
  background: #f0f0f0;
}

app-common-cues-edit .mat-list-item-content{
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
}


.custom-tooltip {
  font-size: 13px;
  max-width: 500px !important;
}

.custom-tooltip::first-line {
  font-weight: bold;
  font-size: 14px !important;
}

.ti-fragment a {
  pointer-events: none;
}