@import '~@angular/material/theming';

$ti-grey: (
  50: #f0f0f0,
  100: #CFD1D2,
  200: #AFB2B4,
  300: #8F9396,
  400: #777B80,
  500: #5F6469,
  600: #575C61,
  700: #4D5256,
  800: #43484C,
  900: #5F6469,
  contrast: (
    50: #4D5256,
    100: #575C61,
    200: #5F6469,
    300: #777B80,
    400: #8F9396,
    500: #AFB2B4,
    600: #CFD1D2,
    700: #f0f0f0,
    800: #f0f0f0,
    900: #f0f0f0,
  )
);

@include mat-core();
$app-primary: mat-palette($ti-grey,  500);
$app-accent: mat-palette($mat-blue, 500);
$app-warn: mat-palette($mat-red, 500);
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
@include angular-material-theme($app-theme);

